<template>
	<main id="liste" v-cloak>
		<HeaderTab :title="$t('global.parrainage')" :element="element" :swiper_tabs="swiper_tabs" />

		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div>
							<LoadingSpinner class="col-12" v-if="!tab_loaded" />
							<router-view v-if="tab_loaded" :code_parrain="code_parrain" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>


<script type="text/javascript">

	import Navigation from "@/mixins/Navigation.js";
	import Parrainage from "@/mixins/Parrainage.js";

	export default {
		name: "Parrainage",
		mixins: [Navigation, Parrainage],
		data () {
			return {
				element: null,
				code_parrain: null,
				param_tab: '',
				actual_tab: '',
				tab_loaded: false,
				swiper_tabs: [{
						id: 'filleul',
						label: 'global.filleul',
						href : 'parrainageFilleul',
						active: false,
					}, {
						id: 'historique_parrainage',
						label: 'global.points',
						href : 'parrainageHistorique',
						active: false
					},
				]
			}
		},
		mounted() {
			this.param_tab = this.$route.params.actual_tab
			this.init_component()
		},
		methods: {
			async init_component() {
				this.tab_loaded = false
				const code_parrain = await this.loadCodeParrain()
				if(!code_parrain) {
					this.$router.push({ name: 'horseListe' })
				} else {
					this.element = { subtitle: this.getTrad('parrainage.votre_code_parrain') + ' : ' + code_parrain.licence_code_parrain }
					this.code_parrain = code_parrain.licence_code_parrain
					this.tab_loaded = true
				}
			}
		},
		components: {
			HeaderTab: () => import('@/components/HeaderTab'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}
</script>
